import React from "react";
import * as styles from "../styles/landing.module.css";
import landing from "../assets/landing.png";
import { Link } from "gatsby";


const About = ({pageContext}) => {
  let language = pageContext.language;
  let allStoriesLink = `/`;
  if (!pageContext.isDefaultLanguage) {
    allStoriesLink = `/${language}/`;
  }

  return (
    <section className={styles.aboutcontainer}>
      <div className={styles.aboutImgWrapper}>
        <img
          className={styles.aboutImg}
          src={landing}
          alt="Logo of Everyone is good at something"
        />
      </div>
      <div className={styles.h2Div}>
        <h2 className={styles.landingh2}>
          <span>
            {pageContext.messages.everyone}
            </span>{" "}
          <span>
            <div className={styles.dot}></div> {pageContext.messages.is}{" "}
            <div className={styles.dot}></div>
          </span>{" "}
          <span>{pageContext.messages.good} </span>
          <span>
            <div className={styles.dot}></div> {pageContext.messages.at}{" "}
            <div className={styles.dot}></div>
          </span>{" "}
          <span>{pageContext.messages.something}</span>
        </h2>
      </div>
      <div className={styles.aboutContentWrapper}>
        <p className={styles.aboutContentText}>
        {pageContext.messages.aboutPara1}
        </p>
        <p className={styles.aboutContentText}>
        {pageContext.messages.aboutPara2}
        </p>
      </div>
      <div className={styles.aboutViewStoriesWrapper}>
        <span className={styles.scrollDown}>
          <span className={styles.mouse}>
            <span></span>
          </span>
        </span>
        <Link to={allStoriesLink}>
          <h2 className={styles.addHover}>{pageContext.messages.viewStories}</h2>
        </Link>
      </div>
    </section>
  );
};

export default About;
